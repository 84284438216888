import React from "react";
import { graphql, StaticQuery } from "gatsby";
import Img from "gatsby-image";
import { Helmet } from "react-helmet";
// import moment from "moment";
import Layout from "../components/layout";
import styled from "@emotion/styled";
// const _ = require("underscore");

export default function ReferralCode(props) {
  const title = "Star Citizen Referral Code";
  const metaDescription = "Star Citizen Referral Code";
  const metaUrl = "https://scdata.io/star-citizen-referral-code/";
  const metaImage = "";
  return (
    <Layout>
      <Helmet>
        <title>{`ScData.io`}</title>
        <meta name="description" content={metaDescription} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:type" content="website" />
        <meta property="fb:app_id" content="2445424502355895" />
        <meta property="og:url" content={metaUrl} />
        <meta property="og:image" content={metaImage} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="675" />
        <meta property="og:image:alt" content={title} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="scdata" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:image" content={metaImage} />
        <html lang="en" />
      </Helmet>

      <Container>
        <h1>Star Citizen Referral Code</h1>
        <FlexContainer>
          <Left>
            <h3><a href="https://robertsspaceindustries.com/enlist?referral=STAR-JN3Y-CT7Y">Use Referral Code: STAR-JN3Y-CT7Y</a></h3>
            <p>When creating your Star Citizen account, use the referral code to get free 5,000 UEC</p>
            <h3>Requrement for the Referral Bonus</h3>
            <p>You will qualify for the referral bonus when you spend $40 or more. Outside of a couple of "Free Fly" periods throught the year a Game Package with a ship is required to play the game.</p>
            <p>You must enter the Referral Code during account creation on the <a href="https://robertsspaceindustries.com/enlist?referral=STAR-JN3Y-CT7Y">Enlist page</a>. You will not get an opportunity to enter it later.</p>
          </Left>
          <StaticQuery
            query={graphql`
                query {
                  referralImage: file(
                    relativePath: { eq: "Star-Citizen-Referral.jpg" }
                  ) {
                    childImageSharp {
                      fixed(width: 420, height: 700, quality: 95) {
                        ...GatsbyImageSharpFixed_withWebp
                      }
                    }
                  }
                }
              `}
            render={data => (
              <a href="https://robertsspaceindustries.com/enlist?referral=STAR-JN3Y-CT7Y">
                <Img
                  fixed={data.referralImage.childImageSharp.fixed}
                  alt="Star Citizen Referral STAR-JN3Y-CT7Y"
                />
              </a>
            )}
          />
        </FlexContainer>
      </Container>
    </Layout >
  );
}


const Container = styled.div``;
const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media (min-width: 719px) {
    flex-wrap: nowrap;  
  }
`;
const Left = styled.div`
  margin-right: 5px;
`;